import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NbAuthModule, NbPasswordAuthStrategy } from "@nebular/auth";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import {
    NbAlertModule,
    NbButtonModule,
    NbDatepickerModule,
    NbDialogModule,
    NbGlobalPhysicalPosition,
    NbIconModule,
    NbInputModule,
    NbMenuModule,
    NbSidebarModule,
    NbThemeModule,
    NbToastrModule
} from "@nebular/theme";
import { environment } from "../environments/environment";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { LoginComponent } from "./login/login.component";

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule( {
    declarations: [
        AppComponent,
        LoginComponent,
    ],
    imports:      [
        AngularFireModule.initializeApp( environment.firebase, "DPP-SUPPORT" ),
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        AppRoutingModule,
        CoreModule,
        CommonModule,
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbDatepickerModule.forRoot(),
        NbDialogModule.forRoot(),
        NbEvaIconsModule,
        NbIconModule,
        NbThemeModule.forRoot( { name: "support" } ),
        NbToastrModule.forRoot( {
            duration:          3000,
            preventDuplicates: true,
            status:            "success",
            position:          NbGlobalPhysicalPosition.BOTTOM_RIGHT
        } ),
        NbAuthModule.forRoot( {
            strategies: [
                NbPasswordAuthStrategy.setup( {
                    baseEndpoint: "https://www.googleapis.com/identitytoolkit/v3/relyingparty",
                    name:         "email",
                    login:        {
                        endpoint: "/verifyPassword?key=" + environment.firebase.apiKey,
                    },
                    token:        {
                        key: "idToken"
                    }
                } ),
            ],
            forms:      {},
        } ),
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot()
    ],
    providers:    [],
    bootstrap:    [ AppComponent ]
} )
export class AppModule {
}

import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { nbAuthCreateToken, NbAuthJWTToken, NbAuthResult, NbAuthService, NbAuthToken, NbTokenService } from "@nebular/auth";

import { from, Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  favoriteColor?: string;
}

@Injectable({ providedIn: "root" })
export class AuthService extends NbAuthService {

  user: Observable<User>;

  constructor(
    private auth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    tokenService: NbTokenService
  ) {
    super(tokenService, {});

    //// Get auth data, then get firestore user document || null
    this.user = this.auth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`user/${ user.uid }`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  getToken(): Observable<NbAuthToken> {
    return this.tokenService.get();
  }

  isAuthenticated(): Observable<boolean> {
    return this.getToken().pipe(
      map((token: NbAuthToken) => {
        return token.isValid();
      })
    );
  }

  authenticate(strategyName: string, data?: any): Observable<NbAuthResult> {
    const signIn = from(this.auth.signInWithEmailAndPassword(data.email, data.password));

    return signIn.pipe(
      map((res: firebase.auth.UserCredential) => {
        let authResult = new NbAuthResult(true, res, "/auth/login", [], []);

        if (res) {
          authResult = new NbAuthResult(true, res, "/dashboard", [], []);
        }

        return authResult;
      }),
      switchMap((authResult) => {
        return this.auth.idToken.pipe(take(1)).pipe(switchMap(idToken => {
          const fToken = nbAuthCreateToken(NbAuthJWTToken, idToken, "email");

          return this.tokenService.set(fToken).pipe(map((test) => {
            return authResult;
          }));
        }));
      })
    );
  }

  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`user/${ user.uid }`);

    const data: User = {
      uid:         user.uid,
      email:       user.email,
      displayName: user.displayName,
      photoURL:    user.photoURL
    };

    return userRef.set(data, { merge: true });
  }

  signOut(): Observable<NbAuthResult> {
    return from(this.auth.signOut()).pipe(map(() => {
      return this.tokenService.clear();
    }), switchMap(() => {
      return of(new NbAuthResult(true, {}, "/auth/login", []));
    }));
  }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor ( private auth: AuthService, private router: Router ) {
    }

    canActivate ( next: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
        return this.auth.isAuthenticated().pipe( tap( authenticated => {
            if ( !authenticated ) {
                console.log( "Access Denied" );
                this.router.navigate( [ "auth/login" ] );
            }
        } ) );
    }
}

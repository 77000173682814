import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NbAuthComponent, NbLogoutComponent } from "@nebular/auth";
import { AuthGuard } from "./core/auth.guard";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
    {
        path:       "",
        redirectTo: "/dashboard",
        pathMatch:  "full"
    },
    {
        path:        "dashboard",
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    }, {
        path:      "auth",
        component: NbAuthComponent,
        children:  [
            {
                path:      "",
                component: LoginComponent,
            },
            {
                path:      "login",
                component: LoginComponent,
            },
            {
                path:      "logout",
                component: NbLogoutComponent,
            }
        ],
    },

    { path: "**", redirectTo: "/dashboard" }
];

@NgModule( {
    imports: [ RouterModule.forRoot( routes ) ],
    exports: [ RouterModule ]
} )
export class AppRoutingModule {
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { NbCardModule } from "@nebular/theme";
import { AuthGuard } from "./auth.guard";
import { AuthService } from "./auth.service";
import { DialogFieldNamePromptComponent } from "./dialogs/dialog-field-name-prompt/dialog-field-name-prompt.component";

@NgModule( {
    declarations: [ DialogFieldNamePromptComponent ],
    imports:      [
        AngularFireAuthModule,
        AngularFirestoreModule,
        CommonModule,
        NbCardModule
    ],
    entryComponents: [ DialogFieldNamePromptComponent ],
    providers:    [ AuthService, AuthGuard ],
    exports: [ DialogFieldNamePromptComponent ]
} )
export class CoreModule {
}

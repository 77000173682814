import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { NB_AUTH_OPTIONS, NbAuthResult, NbLoginComponent } from "@nebular/auth";
import { AuthService } from "../core/auth.service";

@Component( {
    selector:    "app-login",
    templateUrl: "./login.component.html",
    styleUrls:   [ "./login.component.scss" ]
} )
export class LoginComponent extends NbLoginComponent {

    constructor ( protected service: AuthService,
                  @Inject( NB_AUTH_OPTIONS ) protected options = {},
                  protected cd: ChangeDetectorRef,
                  protected router: Router ) {

        super( service, options, cd, router );

        this.showMessages = this.getConfigValue( "forms.login.showMessages" );
    }

    login (): void {
        this.errors = [];
        this.messages = [];
        this.submitted = true;

        this.service.authenticate( "firebase", this.user ).subscribe( ( result: NbAuthResult ) => {
            this.submitted = false;

            console.log( result );

            if ( result.isSuccess() ) {
                this.messages = result.getMessages();
            } else {
                this.errors = result.getErrors();
            }

            const redirect = result.getRedirect();

            if ( redirect ) {
                setTimeout( () => {
                    return this.router.navigateByUrl( redirect );
                }, this.redirectDelay );
            }

            this.cd.detectChanges();
        } );
    }

}

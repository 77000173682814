import { Component } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";

@Component( {
    selector:    "app-dialog-field-name-prompt",
    templateUrl: "./dialog-field-name-prompt.component.html",
    styleUrls:   [ "./dialog-field-name-prompt.component.scss" ]
} )
export class DialogFieldNamePromptComponent {

    constructor ( protected ref: NbDialogRef<DialogFieldNamePromptComponent> ) {
    }

    cancel () {
        this.ref.close();
    }

    submit ( name ) {
        this.ref.close( name );
    }

}
